<template>
  <div id="header">
    <div id="flash-outer" class="display-b">
      <router-link to="/sporsmal" id="flash" class="container">
        <div class="first">Hvem kan kjøpe klokken?</div>
        <div>Se ofte stilte spørsmål →</div>
      </router-link>
    </div>
    <div id="top-bar-outer">
      <div id="top-bar" class="container">
        <router-link to="/" id="logo"><img src="./logo-icon.svg"/></router-link>
        <nav>
          <router-link to="/" exact>Klokken</router-link>
          <router-link to="/spesifikasjoner">Spesifikasjoner</router-link>
          <router-link to="/prosjektet">Prosjektet</router-link>
          <router-link class="display-m" to="/sporsmal">Spørsmål</router-link>
          <!--
          <router-link to="/kjop">Kjøp</router-link>
          -->
          <a :href="KJOP_URL" v-if="false">Kjøp</a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { KJOP_URL } from "../../data.js";
export default {
  data() {
    return { KJOP_URL }
  }
}
</script>

<style lang="scss" scoped>
@import "../../css/system";

#header {
  z-index: 9999;
  position: relative;
}

#flash-outer {
  background-color: #202020;
  padding: 0.25rem 0;
}

#flash {
  font-family: Karelia;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  div.first {
    flex: 1;
  }
}

#top-bar-outer {
  background-color: $middle-grey;
}

#top-bar {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  @include m {
    width: 100%;
    max-width: 1100px;
  }
}

#logo {
  display: flex;
  align-items: center;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  a {
    margin-left: 1.1875rem;
    &:first-child {
      margin-left: 0rem;
    }
    margin-top: 16px;
    padding-bottom: 15px;
    border-bottom: 3px solid rgba(0,0,0,0);
    text-decoration: none;

    @include TextM12;
    @include m {
      @include TextD14;
    }
  }
  a.router-link-active {
    border-bottom: 3px solid white;
  }
}
</style>
