<template>
  <div>
    <Sequence v-bind:time="time" v-bind:config="config"></Sequence>
    <ToggleTimeline size="large" v-on:change="onProgress">
      <template v-slot:a><span class="label">{{left}}</span></template>
      <template v-slot:b><span class="label">{{right}}</span></template>
    </ToggleTimeline>
  </div>
</template>

<script>
import Vue from "vue";
import ToggleTimeline from "./ToggleTimeline/ToggleTimeline";
import Sequence from "./Sequence";

export default Vue.extend({
  data: function() {
    return {time: 0}
  },
  props: ["config", "left", "right"],
  methods: {
    onProgress: function(t) {
      this.time = t;
    }
  },
  components: {Sequence, ToggleTimeline}
})
</script>

<style lang="scss" scoped>
.frame {
  margin-bottom: 1rem;
}
.label {
  position: relative;
  top: -.15rem;
}
</style>
