<template>
  <div class="page container m-padded">
    <div class="columns">
      <div class="column left">
        <div id="hero-wrapper">
          <img src="./mm-panser-hero_b@2x.jpg" class="hero display-b"/>
          <img src="./mm-panser-hero_m@2x.jpg" class="hero display-m"/>
        </div>
      </div>

      <div ref="column-right" class="column right container b-padded">
        <div id="top-logos">
          <img src="./top-logo-b.svg"/>
          <img src="./top-logo-a.svg"/>
        </div>

        <h1>Bitit Fyrst er resultatet av et samarbeid mellom Panserbataljonen og Micromilspec.</h1>

        <p class="faded">
          Prosjektet ble startet i desember 2019, hvor Bardufoss var et naturlig første stopp i utviklingen. Ved Setermoen ble Panserbataljonens kjøretøypark, utstyr og historie presentert, og besøket dannet grunnlaget for det videre arbeidet med klokken.
        </p>

        <p class="faded">
          Målet med prosjektet er å skape en unik klokke tuftet på Panserbataljonens identitet, virke og tradisjoner. En mekanisk klokke med skjult symbolikk for “de som vet” – spesialprodusert og ikke tilgjengelig for allmennheten.
        </p>

        <p class="faded">
          Sammen med noen av verdens fremste fabrikker og spesialister, skrives nå et nytt kapittel i norsk klokkehistorie. Etter endt bestilingsperiode kalibreres maskinparken i Sveits, og ferdigstillingen av klokken er estimert til 180 dager. Via prosesser basert på århundrelange håndverkstradisjoner, videreforedles råmaterialer til et raffinert mekanisk presisjonsinstrument. 
        </p>

        <p class="faded">
          Bitit Fyrst er utviklet for å vare i generasjoner – og kan i likhet med kjøretøyparken på Setermoen repareres i det nært uendelige.
        </p>

        <p id="mot-gamle-kjente">
          Møt gamle kjente.<br/>
          <span class="faded">Det er opprettet en Facebook-gruppe for å samle alle med tilknytning til avdelingen siden 1987. Gruppen eksisterer ene og alene av deres engasjement og verving.</span>
        </p>

        <div id="fb-container">
          <div class="fb-group"
             data-href="https://www.facebook.com/groups/564361957507930/"
             :data-width="fbWidth"
             data-show-social-context="true" 
             data-show-metadata="false"
             data-skin="dark">
          </div>
        </div>
      </div>
    </div>
    <FixedCTA/>
  </div>
</template>

<script>
import { KJOP_URL } from "../../data.js";
import RangeSequence from "../ui/RangeSequence";
import FixedCTA from "../ui/FixedCTA";

export default {
  data() {
    return {
      KJOP_URL,
      fbWidth: 310
    }
  },
  mounted() {
    window.addEventListener("resize", this.scaleFb);
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scaleFb);
  },
  methods: {
    scaleFb() {
      //this doesn't work - but could it? The FB SDK rewrites many styles on mount
      this.fbWidth = this.$refs["column-right"].clientWidth;
    }
  },
  components: {RangeSequence, FixedCTA}
}
</script>

<style lang="scss" scoped>
@import "../../css/system";



#fb-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  @include m {
    justify-content: flex-start;
  }
}

p#swiss-made {
 margin-block-start: 3.2125rem;
 margin-block-end: 1.875rem;
}

p#mot-gamle-kjente {
  text-align: center;
  margin-block-start: 5.8125rem;
  margin-block-end: 3.6875rem;
  @include TextM22;
  @include m {
    @include TextD20;
    text-align: left;
  }
}

.columns {
  display: flex;
  flex-direction: column;
  @include m {
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;
  @include m {
    width: 50%;
    margin-right: 7.5rem;
    &:last-child {
      margin-right: 0;
    }
  }

}

#hero-wrapper {
  position: sticky;
  top: 7.182rem;
  .hero {
    width: 100%;
    @include m {
    }
  }
}

#top-logos {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.8125rem;
  margin-bottom: 1.75rem;
  @include m {
    margin-top: 10.31rem;
  }
  img {
    height: 4.37rem;
    margin-right: 1.25rem;
  }
}


</style>
