<template>
  <div class="subpage">
    <div id="top">
      <div id="day-to-night" class="two-up container">
        <section class="crossfade">
          <ImageCrossfade
            srcWidth="1200"
            srcHeight="1815"
            v-bind:time="time">
            <img class="content a" src="./mm-panser-night.jpg"/>
            <img class="content b" src="./mm-panser-sun.jpg"/>
          </ImageCrossfade>
        </section>
        <section class="copy">
          <p>
            Lesbar under alle forhold. <span class="faded">Distinkte markører med selvlysende Swiss Super-LumiNova® sørger for eksepsjonell lesbarhet – selv i stummende mørke.</span>
          </p>
          <ToggleTimeline size="small" v-on:change="onTimeChange">
            <template v-slot:a><img class="timeline-icon" src="./ltd-a-icon.svg"/></template>
            <template v-slot:b><img class="timeline-icon" src="./ltd-b-icon.svg"/></template>
          </ToggleTimeline>
        </section>
      </div>
      <div id="tidsoner" class="two-up reverse container">
        <section>
          <p>
            To tidssoner. <span class="faded">Ved hjelp av et roterbart 12-timers bezel, kan Bitit Fyrst vise tiden i to ulike tidssoner samtidig.</span>
          </p>
          <p class="last">
            Bezelen har hentet formen sin fra drivhjulet på CV90
          </p>
        </section>
        <section>
          <img class="hero" src="./bezel.jpg"/>
        </section>
      </div>
      <div id="dato" class="two-up container">
        <section>
          <img src="./loupe.jpg"/>
        </section>
        <section>
          <p>
            Dato.
            <span class="faded">Et ekstra bredt datovindu fremhever visningen av dato og gjør den tilgjengelig for hurtig avlesing. Praktisk – selv i vår digitale hverdag.</span>
          </p>
        </section>
      </div>
    </div>
    <div id="specs" class="container">
      <h1>Fakta og spesifikasjoner</h1>
      <div id="spec-grid">
        <div class="item">
          <img src="./specs/warranty.svg"/>
          <h4>Garanti</h4>
          <p>5 års internasjonal garanti</p>
        </div>
        <div class="item">
          <img src="./specs/self-winding.svg"/>
          <h4>Mekanisk urverk</h4>
          <p>ETA 2824-2 med automatisk opptrekk</p>
        </div>
        <div class="item">
          <img src="./specs/water-resistance.svg"/>
          <h4>Vannresistens</h4>
          <p>100 meter / 10 ATM</p>
        </div>
        <div class="item">
          <img src="./specs/glass.svg"/>
          <h4>Glass</h4>
          <p>Ripesikkert safirglass med antirefleksbehandling</p>
        </div>
        <div class="item">
          <img src="./specs/power-reserve.svg"/>
          <h4>Gangreserve</h4>
          <p>Cirka 42 timer</p>
        </div>
        <div class="item">
          <img src="./specs/certificate.svg"/>
          <h4>Ekthetsbevis</h4>
          <p>Sertifikat med individuelt serienummer og navn</p>
        </div>
        <div class="item">
          <img src="./specs/luminous.svg"/>
          <h4>Urskive</h4>
          <p>Markører med selvlysende Swiss Super-LumiNova®</p>
        </div>
        <div class="item">
          <img src="./specs/case.svg"/>
          <h4>Kasse</h4>
          <p>Børstet 316L kirurgisk rustfritt stål</p>
        </div>
        <div class="item">
          <img src="./specs/watch-box.svg"/>
          <h4>Displayboks</h4>
          <p>Håndlaget boks av massivt tre</p>
        </div>
        <div class="item">
          <img src="./specs/surprise-object.svg"/>
          <h4>Overraskelse</h4>
          <p>En unik gjenstand som hedrer vårt samarbeid</p>
        </div>
        <div class="item minor">
          <img src="./specs/case-diameter.svg"/>
          42 mm diameter
        </div>
        <div class="item minor">
          <img src="./specs/case-height.svg"/>
          12 mm tykkelse
        </div>
        <div class="item minor">
          <img src="./specs/strap-tools.svg"/>
          Verktøy for remskifte er inkludert
        </div>
        <div class="item minor">
          <img src="./specs/steel-bracelet.svg"/>
          Stållenke er inkludert
        </div>
      </div>
    </div>
    <FixedCTA/>
  </div>
</template>

<script>
import ImageCrossfade from "../ui/ImageCrossfade";
import ToggleTimeline from "../ui/ToggleTimeline/ToggleTimeline";
import ScrollWidthBox from "../ui/ScrollWidthBox";
import FixedCTA from "../ui/FixedCTA";

export default {
  name: 'KlokkenSpesifikasjoner',
  data: function() {
    return {
      time: 0
    }
  },
  methods: {
    onTimeChange: function(time) {
      this.time = time;
    }
  },
  components: { ImageCrossfade, ToggleTimeline, ScrollWidthBox, FixedCTA }
}
</script>

<style lang="scss" scoped>
@import "../../css/system.scss";

#tidsoner {
  background-color: $dark-grey;
  @include TextM22;
  text-align: center;
  img {
    width: 70%;
    margin-bottom: 1rem;
  }
  padding: 2.25rem;
  margin-top: 2.7rem;
  margin-bottom: 2.1875rem;
  p.last {
    @include TextM18;
    margin-top: 1.875rem;
  }
  @include m {
    @include TextD30;
    padding: 3.9375rem 6.25rem;
    img {
      width: 90%;
    }
    p {
      text-align: left;
    }
    p.last {
      margin-top: 3.25rem;
      width: 80%;
      @include TextD20;
    }
  }
}


#dato {
  @include TextM22;
  text-align: center;
  img {
    width: 74%;
    margin-bottom: 1rem;
  }
  padding-bottom: 2rem;
  @include m {
    @include TextD30;
    margin-top: 10.5rem;
    padding-bottom: 10.5rem;
    img {
      width: 90%;
      margin-bottom: 0rem;
    }
    p {
      text-align: left;
    }
  }
}


#day-to-night {
  .crossfade {
    margin-top: -12rem;
    margin-bottom: -8rem;
    @include m {
      margin-top: -2rem;
      margin-bottom: 0rem;
      flex: 1.2;
    }
  }
  .toggle-timeline {
    align-self: center;
    width: 60%;
    margin-bottom: 3.1875rem;
    @include m {
      margin-top: 4.1875rem;
      align-self: start;
      width: 13rem;
    }
  }
  .timeline-icon {
    position: relative;
    top: .1rem;
  }
  .copy {
    z-index: 10;
    display: flex;
    flex-direction: column-reverse;

    text-align: center;
    @include TextM22;

    @include m {
      @include TextD30;
      flex-direction: column;
      text-align: left;
      flex: 1;
    }
  }
}

#specs {
  padding-top: 3.5625rem;
  @include m {
    padding-top: 6.875rem;
  }
  h1 {
    font-weight: normal;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 4.18rem;
  }
}

#spec-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1.25rem;
  margin-bottom: 4.625rem;

  @include m {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-column-gap: 0.625rem;
    grid-row-gap: 1.25rem;
  }

  .item {
    padding-right: 0.625rem;
    padding-bottom: 1.56rem;
    border-bottom: 1px solid #484848;
    &.minor {
      grid-column: 1 / span 2;
      display: flex;
      flex-direction: row;
      border-bottom: none;
      align-items: center;
      padding-bottom: 0;
      img {
        margin-right: 0.625rem;
      }

      @include m {
        grid-column: auto;
        padding-bottom: 1.56rem;
      }
    }
    h4 {
      @include TextM14;
      margin-top: 0.625rem;
      margin-bottom: 0.0125rem;
    }
    p {
      margin-top: 0;
      color: $light-grey;
    }
    @include TextM12;
  }
}


#top {
  background-color: black;
  padding-top: 7rem;
  //padding-bottom: 8.4375rem;
}
</style>
