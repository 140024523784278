<template>
<div id="footer" :class="footerClass()">
  <div class="container">
    <div class="separator"></div>

    <img class="crest" src="./crest.svg"/>

    <router-link to="/om-oss">Les om teamet bak</router-link>

    <div id="social">
      <span class="cta">Følg oss</span>
      <div class="icons">
        <a href="https://www.instagram.com/micromilspec/" target="_blank">
          <img src="./instagram.svg"/>
        </a>
        <a href="https://www.facebook.com/micromilspec/" target="_blank">
          <img src="./facebook.svg"/>
        </a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    footerClass() {
       return this.$route.path === "/" ? "filled" : "default";
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../css/system";

#footer {
  padding-bottom: 4.375rem;
  @include m {
    padding-bottom: 6rem;
  }
  &.filled {
    background-color: #080808;
    .separator {
      border: none;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .separator {
    height: 1px;
    width: 100%;
    border-top: 1px dashed $invalid-footer-top;
    margin-bottom: 2.375rem;
  }

  a {
    @include TextM16Underline;
    @include m {
      @include TextD14Underline;
    }
  }

  .crest {
    width: 5rem;
    margin-bottom: 3.4375rem;
  }

  .icons {
    text-align: center;

    a {
      text-decoration: none;
      padding: 0 .75rem;
    }
  }

  .cta {
    text-align: center;
    display: block;
    margin: 1.625rem 0rem;
    @include TextM12Uppercase;
    @include m {
      @include TextD14Uppercase;
    }
    opacity: 0.5;
  }
}
</style>
