export const KLOKKEMODELL = "Bitit Fyrst";
export const URVERK = "2824-2";
export const GANGRESERVETIMER = 42;
export const SAMARBEIDSPARTNER = "Panserbataljonen"
export const PRICE = "9.500 kroner"
export const KJOP_URL = "https://checkout.micromilspec.com/4c7fde5f-6964-4d55-8bd3-e03da4e3c2de";

export const FAQ = [
  {
    title: `Hvem kan kjøpe klokken?`,
    value: `Hvis du har kommet til denne siden, så er klokken mest sannsynlig tiltenkt deg. Informasjon om salget blir spredt på sosiale medier og du trenger ikke invitasjon for å kjøpe klokken.

<b>Klokken er kun tilgjengelig for kjøp gjennom denne nettsiden og kan bestilles av privatpersoner som:</b>
Har eller har hatt en relasjon til Panserbataljonen enten som ansatt, vernepliktig, støtteavdeling under deployering eller øvelse.

Du kan også kjøpe klokken om du har blitt invitert av noen i Panserbataljonen, uten å ha en relasjon. 

Oppfyller du noen av kriteriene over trenger du ikke vise dokumentasjon eller bli invitert for å kjøpe klokken. 

<b>Hvem kan ikke bestille klokken?</b>
Hvis det ikke finnes noen tilknytning eller relasjon til Panserbataljonen kan du ikke kjøpe klokken.`
  },
  {
    title: `Hvorfor koster ${KLOKKEMODELL} ${PRICE}?`,
    value: `${KLOKKEMODELL} er en spesialprodusert klokke som er konstruert for å vare i generasjoner. Flere av de essensielle komponentene er produsert på maskiner som er satt opp og kalibrert spesielt for denne klokken. ${KLOKKEMODELL} eksisterte ikke fra før – og vil heller ikke reproduseres senere utenfor avdelingen.

Urverket er mekanisk og produseres i Sveits etter strenge kvalitetskriterier. ETA ${KLOKKEMODELL} er anerkjent som en presis og robust arbeidshest, og benyttes hos flere av verdens ypperste klokkeprodusenter.`,
  },
  {
    title: `Tilbyr dere delbetaling?`,
    value: `Ja, og du kan velge mellom betalingsintervaller på 2 eller 6 måneder.`,
  },
  {
    title: `Hva menes med “Swiss Made”?`,
    value: `Som en videreføring sveitsiske håndverkstradisjoner og fokus på kvalitet, er klokken produsert i tråd med vedtekene om “Swiss Made” (innført 1. januar 2019), som definert av det sveitsiske Forbundsrådet.

“Swiss Made” er i dag en beskyttet designasjon. Kriteriene krever at klokken benytter et sveitsisk urverk, samt at minst 60 prosent av klokken (og komponentene) er produsert i Sveits. Sluttmontering og siste inspeksjon må også skje i Sveits. Slik sikres den høye kvaliteten man forventer av en sveitsisk klokke.`,
  },
  {
    title: `Hva menes med “mekanisk urverk” og “selvtrekkende”?`,
    value: `En mekanisk klokke drives av en opptrekkbar fjær – ikke elektronikk og batterier. Klokken kan trekkes opp for hånd, men vil også trekkes når den er i bruk. Gjennom et perfekt kalibrert mekanisk samspill, sørger bevegelsen til et lite lodd (den såkalte “rotoren”) for at drivfjæren trekkes opp. Fullt opptrukket har klokken en gangreserve på ${GANGRESERVETIMER} timer.`,
  },
  {
    title: `Når er klokken min klar for levering?`,
    value: `Estimert leveringstid er 24 uker fra bestilling.`,
  },
  {
    title: `Hva dekker deres 5 års internasjonale garanti?`,
    value: `Vår klasseledende garanti dekker alle produksjonsfeil, inkludert komponenter som urverk, urskive, visere og rem. Kort og godt – er det vår skyld at klokken ikke fungerer som den skal, utbedrer vi feilen eller erstatter klokken i sin helhet. Garantien dekker imidlertid ikke feil og mangler som følge av brukerpåførte skader og feilaktig bruk. Bruksslitasje, riper, vannskade og tyveri dekkes heller ikke.

Vær spesielt oppmerksom på at lærremmer skades av vann og fuktighet – også når selve klokken tåler vann. En periodisk trykktest av klokken hvert 1-2 år er dessuten nødvendig for å verifisere at klokken fortsatt er vannresistent.

Dersom en klokke returneres i løpet av garantiperioden, vil MICROMILSPEC reparere eller erstatte klokken og komponenter kostnadsfritt, og innen rimelig tid. Kunden kan imidlertid bli ansvarlig for å dekke eventuelle fraktkostnader.`,
  },
  {
    title: `Trenger jeg å trekke klokken eller å skifte batteri på den?`,
    value: `Nei, klokken er mekanisk og selvtrekkende. Ved hjelp av kroppens bevegelse vil klokken trekkes automatisk på håndleddet. Full gangreserve på 42 timer oppnås ordinært etter rundt 4 til 5 timers bruk. Klokken kan også trekkes opp manuelt ved å vri på kronen, hvor en tydelig følt endring i friksjon signaliserer at urverket er fullt opptrukket.`,
  },
  {
    title: `Hvor kan jeg levere klokken til service og hvor mye koster det?`,
    value: `${KLOKKEMODELL} har ingen elektronikk som kan bli avleggs, men består utelukkende av mekaniske komponenter som kan repareres eller erstattes. Ved riktig vedlikehold og service vil klokken kunne vare i generasjoner.

Alle mekaniske urverk bør renses, oljes og justeres, og vårt anbefalte serviceintervall er 5 år (i tråd med de fleste mekaniske klokker). En slik periodisk overhaling vil ordinært koste rundt 4.000 kroner hos en urmaker. Vi tilbyr imidlertid full service av ${KLOKKEMODELL} gjennom vår MICROMILSPEC-godkjente urmaker for 2.500 kroner.`,
  },
  {
    title: `Tåler klokken ekstreme værforhold og ytre påkjenninger?`,
    value: `Ja – men den tåler ikke kraftige sammenstøt, fall eller slag mot harde overflater som betong, fliser og stein. Dette kan resultere i ytre skader som riper, hakk og knust glass – men også skader på skjulte innvendige komponenter.`,
  },
  {
    title: `Hvordan tar jeg best mulig vare på min klokke?`,
    value: `En egen link med instruksjoner for bruk og vedlikehold sendes til e-postadressen som er registrert på din ordre.`,
  },
  {
    title: `Kan ${KLOKKEMODELL} poleres?`,
    value: `Ja og nei. Klokken kan poleres, men den børstede finisjen vil forsvinne. En refinisjering kan imidlertid foretas hos en rekke urmakere og serviceverksteder ved behov.`,
  }
];
