import regeneratorRuntime from "regenerator-runtime";
import App from './components/App.vue';

class Index {
  constructor() {
    document.querySelector("body").classList.remove("no-js");
    document.querySelector("body").classList.add("js");

    if (window.fontLoadComplete) {}

    new App().$mount('#app');
  }
}

window.index = new Index()
