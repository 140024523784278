<template>
  <form class="email-form" v-bind:action="action">
    <input id="site_id" name="site_id" type="hidden" v-bind:value="siteId"></input>
    <input id="email" name="email" type="email" v-model="email" placeholder="Din e-post"></input>
    <button type="submit">Fortsett<div class="spinner"><div class="lds-dual-ring"></div></div></button>
    <div class="error">Something went wrong.</div>
    <div class="success">Thank you for getting in touch.<br>Information about our test program will be with your shortly.</div>
  </form>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: ["action", "siteId"],
  data() {
    return {email: ""}
  }
});

export class EmailForm {
  constructor(selector) {
    this.el = document.querySelector(selector);
    this.emailEl = this.el.querySelector("#email");
    this.submitEl = this.el.querySelector("button[type=submit]");
    this.el.addEventListener("submit", this.onSubmit.bind(this))
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.emailEl.value === "") {
      return;
    }

    const formData = new FormData(this.el);
    const payload = JSON.stringify(Object.fromEntries(formData));

    const XHR = new XMLHttpRequest();
    XHR.addEventListener("load", () => {
      if (XHR.status < 400) {
        this.onSuccess();
      } else {
        this.onError();
      }
    });
    XHR.addEventListener("error", this.onError.bind(this));
    XHR.open("POST", this.el.action);
    XHR.setRequestHeader('Content-Type', 'application/json');
    XHR.send(payload);

    this.submitEl.disabled = true;
  }

  onSuccess() {
    this.el.reset();
    this.submitEl.disabled = false;
    this.el.classList.remove("error");
    this.el.classList.add("success");
  }

  onError() {
    this.submitEl.disabled = false;
    this.el.classList.remove("success");
    this.el.classList.add("error");
  }
}

</script>

<style lang="scss" scoped>
@import "../../css/system.scss";

form {
  font-family: Karelia;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.188rem;
  text-align: center;
  color: $superlight-grey;
  justify-content: center;

  display: flex;

  margin-top: 3rem;
  flex-direction: column;
  @include m {
    margin-top: 1rem;
    flex-direction: row;
  }

  input {
    background-color: $invalid-input-background;
    border: 1px solid $invalid-input-background;
    color: $superlight-grey;
    padding: .75rem 4rem;
    text-align: center;
    margin: 0px;

    &:invalid {
      border: 1px solid red;
    }

    margin-bottom: .75rem;
    @include m {
      margin-bottom: 0;
    }
  }

  button {
    position: relative;
    background-color: $yellow;
    color: $black;
    border: none;
    padding: .75rem 2rem;

    .spinner {
      display: none;
    }

    &:disabled {
      background-color: $dim-yellow;
      .spinner {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        display: flex;
        border-radius: 0.250rem;
        align-items: center;
        justify-content: center;
        background-color: $dim-yellow;
      }
    }
  }

  .error {
    display: none;
    color: red; 
    margin-top: 2rem;
  }
  .success {
    display: none;
    color: $yellow;
    margin-top: 2rem;
  }
  &.error {
    .error {
      display: block;
    }
  }
  &.success {
    .success {
      display: block;
    }
  }
}

.lds-dual-ring {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
