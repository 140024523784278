<template>
  <div ref="frame" class="scroll-width-box">
    <slot></slot>
  </div>
</template>

<script>
function ease(t) {
    const sqt = t * t;
    return sqt / (2.0 * (sqt - t) + 1.0);
}
export default {
  name: 'MobileScrollWidthBox',
  data: function() {
    return {time: 0}
  },
  mounted() {
    window.addEventListener("resize", this.update);
    document.addEventListener("scroll", this.update);
    this.update();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.update);
    document.removeEventListener("scroll", this.update);
  },
  methods: {
    update() {
      /*
      const total = window.innerHeight;
      const origin = window.innerHeight / 4;
      const range = total - origin;
      var rect = this.$refs.frame.getBoundingClientRect();
      const contentHeight = this.$refs.frame.clientHeight;
      const t = Math.max(0, Math.min(1, 1 - ((rect.top-contentHeight/3) - origin) / range*.3));

      this.time = t;
      this.$refs.frame.style.minWidth = `${t*100}%`;
      */

      var rect = this.$refs.frame.getBoundingClientRect();
      const contentHeight = this.$refs.frame.clientHeight;
      const o = Math.min(window.innerHeight - contentHeight, window.innerHeight / 4);
      const nearness = 1 - (rect.top - o) / contentHeight;

      const frameWidth = this.$refs.frame.clientWidth;
      const childWidth = this.$refs.frame.firstChild.clientWidth;
      const startPercent = childWidth / frameWidth;

      //const th = Math.max(0, Math.min(1, nearness * 2));
      //const tw = Math.max(0, Math.min(1, (nearness -.5) * 2));
      const t = Math.max(0, Math.min(1, nearness));
      const tw = startPercent + ease(t) * (1-startPercent);

      this.time = t;
      //this.$refs.frame.style.backgroundSize = `${t*100}% ${t*15+85}%`;
      this.$refs.frame.style.backgroundSize = `${tw*100}% 100%`;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../css/system.scss";

.scroll-width-box {
  width: 100%;
  background-size: 100% 100%;
  background: linear-gradient(90deg, #000 50%, #000 50%);
  background-position: center center;
  background-repeat: no-repeat;
  .container {
    margin: none;
  }
}

@include m {
  .scroll-width-box {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: black;

    .container {
      margin: none;
    }
  }
}
</style>
