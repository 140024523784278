<template>
  <div ref="el">
    <Sequence v-bind:time="time" v-bind:config="config"></Sequence>
  </div>
</template>

<script>
import ToggleTimeline from "./ToggleTimeline/ToggleTimeline";
import Sequence from "./Sequence";

export default {
  name: "PositionSequence",
  props: ["config"],
  data: function() {
    return {time: 0}
  },
  mounted() {
    window.addEventListener("resize", this.update);
    document.addEventListener("scroll", this.update);
    this.update();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.update);
    document.removeEventListener("scroll", this.update);
  },
  methods: {
    update() {
      //const positionFromStart = window.pageYOffset;
      //return (positionFromStart / (this.el.clientHeight * SCREENS));

      const total = window.innerHeight;
      const origin = window.innerHeight / 2.45;
      const range = total - origin;

      var rect = this.$refs.el.getBoundingClientRect();
      const contentHeight = (rect.bottom - rect.top);
      const center = rect.top + contentHeight / 2;
      const offset = center - origin;


      const t = (range - offset) / range*1.1;
      if (t < 0 || t > 1) {
        return;
      }

      this.time = t;
    },
  },
  components: {Sequence}
}
</script>

<style lang="scss" scoped>
</style>
