<template>
  <div ref="frame" class="frame">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["srcWidth", "srcHeight", "time"],
  mounted() {
    this.b = this.$refs["frame"].querySelector(".b")
    this.b.style.opacity = this.time;
    this.onResize();
    window.addEventListener("resize", this.onResize.bind(this));
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      const aspect = (this.srcHeight / this.srcWidth);
      this.$refs.frame.style.height = this.$refs.frame.clientWidth * aspect + "px";
    }
  },
  watch: {
    time: function(newTime) {
      this.b.style.opacity = newTime;
    }
  }
}
</script>

<style lang="scss" scoped>
.frame {
  width: 100%;
  position: relative;

  .content {
    position: absolute;
    left: 0px;
    transform-origin: 0% 0%;
    width: 100%;
  }
}
</style>
