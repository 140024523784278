<template>
  <div class="engraving-preview">

    <div class="copy">
      <slot name="copy"></slot>
    </div>

    <div ref="renderer" class="renderer">
      <div ref="rendererInner" class="renderer-inner">
        <div class="image">
          <slot></slot>
        </div>
        <svg v-for="(item, idx) in engravings" :width="nativeWidth" :height="nativeHeight" class="engraving" :key="item.label" :viewBox="`0 0 ${nativeWidth} ${nativeHeight}`">
          <defs>
            <filter id="shadow-a" width="150%" height="150%">
              <feGaussianBlur stdDeviation="3"/>
              <feOffset dx="0" dy="-2"/>
            </filter>
            <filter id="shadow-b" width="150%" height="150%">
              <feGaussianBlur stdDeviation="6"/>
              <feOffset dx="0" dy="5"/>
            </filter>
          </defs>
          <path id="arc" fill="transparent" :d="`M ${originX-item.radius} ${originY} A ${item.radius},${item.radius} 0 1 0 ${originX+item.radius},${originY}`" />
          <text style="filter: url(#shadow-a); fill: rgba(0,0,0,.8);" :width="nativeWidth" text-anchor="middle">
            <textPath xlink:href="#arc" startOffset="50%">
              {{e(idx).value}}
            </textPath>
          </text>
          <text style="filter: url(#shadow-b); fill: rgba(0,0,0,.6);" :width="nativeWidth" text-anchor="middle">
            <textPath xlink:href="#arc" startOffset="50%">
              {{e(idx).value}}
            </textPath>
          </text>
          <text :width="nativeWidth" text-anchor="middle">
            <textPath xlink:href="#arc" startOffset="50%">
              {{e(idx).value}}
            </textPath>
          </text>
        </svg>
      </div>
    </div>

    <form class="form">
      <slot name="form-head"></slot>
      <fieldset v-for="(item, idx) in engravings" :key="item.label">
        <div class="labels">
          <label>{{ item.label }}</label>
          <div class="spacer"></div>
          <label>{{ item.remaining }}</label>
        </div>
        <input v-model="e(idx).value" type="text" :placeholder="item.placeholder"></input>
      </fieldset>
    </form>

  </div>
</template>

<script>
export default {
  name: 'EngravingPreview',
  props: {
    nativeWidth: {
      type: Number,
      default: 1024
    },
    nativeHeight: {
      type: Number,
      default: 1024
    },
    originU: {
      type: Number,
      default: .5
    },
    originV: {
      type: Number,
      default: .5
    },
    inputs: Array
  },
  data() {
    const engravings = this.inputs.map((x) => {
      return {...x, ...{
        radius: x.radius * this.nativeWidth,
        value: "",
        remaining: x.maxCharacters
      }};
    });

    return {
      originX: this.nativeWidth * this.originU,
      originY: this.nativeHeight * this.originV,
      aspectRatio: this.nativeHeight / this.nativeWidth,
      engravings
    }
  },
  beforeUpdate() {
    for (const x of this.engravings) {
      x.value = x.value.slice(0, x.maxCharacters)
      x.remaining = x.maxCharacters - x.value.length;
    }
  },
  mounted() {
    this.scaleRenderer();
    window.addEventListener("resize", this.scaleRenderer);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scaleRenderer);
  },
  methods: {
    e(idx) {
      if (idx === undefined) {
        return {};
      }
      return this.engravings[idx];
    },
    scaleRenderer() {
      const renderer = this.$refs.renderer;
      const width = renderer.clientWidth;
      const height = width * this.aspectRatio;
      const scale = width / this.nativeWidth;
      this.$refs.renderer.style.height = height + "px";
      this.$refs.rendererInner.style.transform = `scale(${scale})`;
    }
  }
}
</script>

<style lang="scss" scoped>
.engraving-preview {
  display: grid;

  .renderer-inner {
    position: relative;
    transform-origin: 0% 0%;
    .engraving {
      position: absolute;
      top: 0px;
      left: 0px;
      text {
        text-transform: uppercase;
      }
    }
    .image {
      position: absolute;
      top: 0px;
      left: 0px;
      img {
        display: block;
      }
    }
  }

  .form {
    input {
      width: 100%;
      border: none;
      margin: 0px;
      display: block;
    }

    fieldset {
      padding: 0;
      border: none;
    }

    .labels {
      display: flex;
      flex-direction: row;
      .spacer {
        flex: 1;
      }
    }
  }
}
</style>
