<template>
<div ref="frame" class="gallery">
  <div ref="images" class="images">
    <slot></slot>
  </div>
  <div class="overlay">
    <div class="container">
      <div class="legend">
        <p>
          Bildegalleri<br/>
          <span class="faded">Sveip for neste</span>
        </p>
        <div class="nav">
          <div v-for="(m,i) in numImages" :class="`marker ${i == idx ? 'active' : ''}`"></div>
        </div>
      </div>
      <div class="arrows">
        <img ref="left-arrow" class="arrow left" src="./left-arrow.svg"/>
        <div class="spacer"></div>
        <img ref="right-arrow" class="arrow right" src="./right-arrow.svg"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Hammer from "hammerjs";

export default {
  name: "Gallery",
  data() {
    return {
      numImages: 0,
      idx: 0
    }
  },
  mounted() {
    this.numImages = this.$refs.images.childElementCount;
    this.hammer = new Hammer(this.$refs.frame);
    this.hammer.on("swipeleft swiperight tap press", this.onGesture);
    this.move();
  },
  beforeDestroy() {
    this.hammer.destroy();
  },
  methods: {
    onGesture: function(e) {
      switch (e.type) {
        case "swipeleft":
          this.next();
          break;
        case "swiperight":
          this.prev();
          break;
        case "tap":
        case "press":
          if (e.center.x < this.$refs.frame.clientWidth / 3) {
            this.prev();
          } else if (e.center.x > this.$refs.frame.clientWidth * 2/3) {
            this.next();
          }
          break;
      }
    },
    next: function() {
      if (this.idx < this.numImages -1) {
        this.idx += 1;
      }
      this.move();
    },
    prev: function() {
      if (this.idx > 0) {
        this.idx -= 1;
      }
      this.move();
    },
    move: function() {
      const images = this.$refs.images.children;
      if (this.idx > images.length - 1) {
        this.idx = images.length - 1;
      }
      if (this.idx < 0) {
        return;
      }
      const left = images[this.idx].offsetLeft;

      //const width = images[this.idx].clientWidth;
      //const x = this.$refs.frame.clientWidth / 2 - width / 2 + left;

      this.$refs.images.style.transform = `translate(${-left}px)`;
      this.$refs["left-arrow"].style.opacity = this.idx > 0 ? 1 : 0;
      this.$refs["right-arrow"].style.opacity = this.idx < this.numImages - 1 ? 1 : 0;
    }
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../css/system.scss";

.gallery {
  position: relative;
  overflow: hidden;

  .images {
    position: relative;
    display: flex;
    flex-direction: row;
    img {
      width: 100vw;
    }
    transition: transform .4s ease-out;
  }

  .overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    .container {
      position: relative;
      height: 100%;
    }

    .legend {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 1.25rem;
      right: 1.25rem;
      display: flex;
      @include m {
        top: 3.5625rem;
      }
      flex-direction: column;

      p {
        flex: 1;
      }
      .nav {
        margin-bottom: 1.9375rem;
        justify-content: center;
      }

      @include m {
        p {
          flex: 0;
        }
        .nav {
          margin-top: 0.625rem;
          justify-content: start;
        }
      }

      .nav {
        display: flex;
        flex-direction: row;

        .marker {
          width: 26px;
          height: 3.02px;
          border-radius: 6px;
          margin-right: 0.3125rem;
          background: white;
          transition: opacity .2s ease-in;

          opacity: .3;
          &.active {
            opacity: 1;
          }
        }
      }
    }

    .arrows {
      display: none;
      @include m {
        display: flex;
      }
      width: 100%;
      height: 100%;
      flex-direction: row;
      align-items: center;

      .arrow {
        opacity: 0;
        display: block;
        transition: opacity .2s ease-in;
      }

      .spacer {
        flex: 1;
      }
    }
  }
}
</style>
